<template>
  <div class="edit-connection">
    <CForm
      ref="updateTag"
      v-on:submit.prevent="updateConnection"
      novalidate
      class="update-tag-form"
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="!isReady"
        :descriptions="descriptions"
        :title="dynamicTitleKey"
        submit-title="update"
      />
      <FormBuilder
        :list="formList"
        :isCreateHeader="false"
        @updated="updateFormData"
        :page="{
          title: 'Update Connection',
          info: descriptions,
          mode: 'update',
        }"
      />
    </CForm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {delay, isEqual} from 'lodash';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from '@/config';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import CreateHeader from '@/shared/components/CreateHeader';
import { connections } from '@/config/descriptions/connections';
import { connectionsTexts } from '@/config/pageTexts/connections.json';
import connectionFields from '@/store/modules/settings/connections/fields';
import fieldHelpers from '@/utils/ZiqniFieldHelper.js';

export default {
  name: 'EditConnection',
  components: {
    CreateHeader,
  },
  data() {
    return {
      model: 'Connection',
      descriptions: {
        ...connections.edit,
      },
      texts: {
        ...connectionsTexts,
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      removeLabel: formConfig.removeLabel,
      customFields: [],
      formList: [],
      formData: {
        id: '',
        created: '',
        objectType: 'SqsConnection',
        name: '',
        description: '',
        uri: '',
        acessKey: '',
        secretKey: '',
        transformerId: '',
        customFields: {},
        metadata: [],
        tags: [],
      },
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      columnFilter: false,
      itemsPerPage: pageConfig.itemsPerPage,
      nameValidate: null,
      transValidate: null,
      transId: '',
      uriValidate: null,
      accessValidate: null,
      secretValidate: null,
      isReady: false,
      userConstraints: [],
      connectionType: ''
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('connections', ['success', 'message', 'loading', 'connections', 'connection']),
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className =
        this.theme === 'main'
          ? 'error-validation--multiselect'
          : 'error-validation';

      return {
        [className]: this.transValidate === false,
      };
    },
    created() {
      return dateFormate(this.formData.created);
    },
    dynamicTitleKey() {
      const dynamicKey = 'edit' + this.connectionType;

      if (dynamicKey in this.texts) {
        return this.texts[dynamicKey].title;
      } else {
        return 'Update Connection';
      }
    }
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        if (val.transformerId) this.transValidate = true;
        if (val.name) this.nameValidate = true;
        if (val.uri) this.uriValidate = true;
        if (val.acessKey) this.accessValidate = true;
        if (val.secretKey) this.secretValidate = true;
      },
    },
    connectionType(val) {
      this.dynamicTitleKey;
    }
  },
  methods: {
    ...mapActions('connections', [
      'handleUpdateSQSConnections',
      'handleGetSQSConnectionsById',
      'handleGetConnections',
      'describeConnectionObject',
      'handleUpdateConnections'
    ]),
    initialize() {
      this.handleGetConnections([this.$route.params.id], 1, 0)
        .then(async data => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].name,
          });
          this.connectionType = data[0].connectionType;

          let connectionTypeValue
          switch (this.connectionType) {
            case 'SQS':
              connectionTypeValue = 'sqs';
              break
            case 'Kafka':
              connectionTypeValue = 'kafka';
              break
            case 'RabbitMq':
              connectionTypeValue = 'rabbitMq';
              break
            case 'Scheduled':
              connectionTypeValue = 'scheduled';
              break
          }

          if (data.length) {
            this.formData = data[0];
            this.formList = fieldHelpers.prepareEditFormList(
              connectionFields,
              this.texts.editPage,
              this.descriptions[connectionTypeValue],
              this.formData
            );

            const connectionObject = await this.describeConnectionObject(data[0].connectionType.toUpperCase());
            this.customFields = connectionObject.customFields;
            this.userConstraints = connectionObject.userConstraints;

            if (this.customFields.length) {

              this.customFields.forEach(f => {
                const { key } = f;

                const customField = {
                  'key': f.key,
                  'label': f.name,
                  'tooltip': this.descriptions[connectionTypeValue][key] ? this.descriptions[connectionTypeValue][key] : f.description ? f.description : '',
                  'type': f.fieldType.toUpperCase(),
                  'model': 'UpdateConnectionRequest',
                  'value': this.formData.customFields[key] ?? null,
                  'disabled': false,
                  'fieldDescriptor': {
                    'name': f.name,
                    'complexType': null,
                    'displayOrder': 2000,
                    'allowableValuesKeys': [],
                    'constraints': f.constraints,
                    'formType': null
                  },
                  'options': null,
                  'multiple': false,
                  'required': f.constraints ? f.constraints.includes('required') : false,
                  'showKey': false,
                  'isCreate': false,
                  'isUpdate': false
                };
                this.formList.push(customField);
              })
            }

            if (this.userConstraints.length) {
              const formDataConstraints = this.formData.constraints.map(item => item.trim());
              this.userConstraints.forEach(c => {
                const userConstraint = {
                  'key': c.key,
                  'label': c.name,
                  'tooltip': this.descriptions[connectionTypeValue][c.key],
                  'type': 'SWITCH',
                  'model': 'UpdateConnectionRequest',
                  'value': formDataConstraints.indexOf(c.key) > -1,
                  'disabled': false,
                  'fieldDescriptor': {
                    'name': c.name,
                    'complexType': null,
                    'displayOrder': 2000,
                    'allowableValuesKeys': [],
                    'constraints': null,
                    'formType': null
                  },
                  'options': null,
                  'multiple': false,
                  'required': false,
                  'showKey': false,
                  'isCreate': false,
                  'isUpdate': false
                };
                this.formList.unshift(userConstraint);
              });
            }
            this.formList = this.formList.filter(f => f.key !== 'customFields');

            if (data[0].connectionType.toUpperCase()=== 'SQS') {
              this.sortSqsFields();
            }

            if (data[0].connectionType.toUpperCase()=== 'RABBITMQ') {
              this.sortRabbitMqFields();
            }

            if (data[0].connectionType.toUpperCase()=== 'KAFKA') {
              this.sortKafkaFields();
            }
          }
          this.isReady = true;
        });
    },
    sortSqsFields() {
      let metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');

      if (metaDataIdx !== -1) {
        const queueNameIdx = this.formList.findIndex(f => f.key === 'queueName');
        if (queueNameIdx !== -1) {
          const queueName = this.formList.splice(queueNameIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, queueName);
        }

        metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
        const regionNameIdx = this.formList.findIndex(f => f.key === 'region');
        if (regionNameIdx !== -1) {
          const regionName = this.formList.splice(regionNameIdx, 1)[0];
          this.formList.splice(metaDataIdx, 0, regionName);
        }
      }
    },
    sortRabbitMqFields() {
      let metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
      if (metaDataIdx !== -1) {
        const exchangeIdx = this.formList.findIndex(f => f.key === 'exchange');
        if (exchangeIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(exchangeIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const exchangeTypeIdx = this.formList.findIndex(f => f.key === 'exchangeType');
        if (exchangeTypeIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(exchangeTypeIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const portIdx = this.formList.findIndex(f => f.key === 'port');
        if (portIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(portIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const routingKeysIdx = this.formList.findIndex(f => f.key === 'routingKeys');
        if (routingKeysIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(routingKeysIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const uriIdx = this.formList.findIndex(f => f.key === 'uri');
        if (uriIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(uriIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const virtualHostIdx = this.formList.findIndex(f => f.key === 'virtualHost');
        if (virtualHostIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(virtualHostIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const queueExpiresIdx = this.formList.findIndex(f => f.key === 'x-expires');
        if (queueExpiresIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(queueExpiresIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const queueModeIdx = this.formList.findIndex(f => f.key === 'x-queue-mode');
        if (queueModeIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(queueModeIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const queueNameIdx = this.formList.findIndex(f => f.key === 'queueName');
        if (queueNameIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(queueNameIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const regionNameIdx = this.formList.findIndex(f => f.key === 'region');
        if (regionNameIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(regionNameIdx, 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }
      }
    },
    sortKafkaFields() {
      let metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');

      if (metaDataIdx !== -1) {
        const brokersIdx = this.formList.findIndex(f => f.key === 'brokers');
        if (brokersIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(brokersIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const groupIdIdx = this.formList.findIndex(f => f.key === 'groupId');
        if (groupIdIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(groupIdIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const topicIdx = this.formList.findIndex(f => f.key === 'topic');
        if (topicIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(topicIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const jksUriIdx = this.formList.findIndex(f => f.key === 'jksUri');
        if (jksUriIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(jksUriIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const tksUriIdx = this.formList.findIndex(f => f.key === 'tksUri');
        if (tksUriIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(tksUriIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const keyDeserializerIdx = this.formList.findIndex(f => f.key === 'key.deserializer');
        if (keyDeserializerIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(keyDeserializerIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const valueDeserializerIdx = this.formList.findIndex(f => f.key === 'value.deserializer');
        if (valueDeserializerIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(valueDeserializerIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }

        const regionIdx = this.formList.findIndex(f => f.key === 'region');
        if (regionIdx !== -1) {
          metaDataIdx = this.formList.findIndex(f => f.key === 'metadata');
          const element = this.formList.splice(regionIdx , 1)[0];
          this.formList.splice(metaDataIdx, 0, element);
        }
      }
    },
    updateFormData(val) {
      if (this.formData && !isEqual(this.formData, val)) {
        this.isSettingsDataSaved = false;
      }
      this.formData = val;
    },
    dateFormate,
    updateConnection() {
      this.transValidate = !!this.formData.transformerId;
      this.nameValidate = !!this.formData.name;
      this.uriValidate = !!this.formData.uri;
      this.accessValidate = !!this.formData.acessKey;
      this.secretValidate = !!this.formData.secretKey;

      delete this.formData.spaceName;
      delete this.formData.created;
      delete this.formData.lastKnownStatus;
      delete this.formData.lastKnownStatusCode;

      let customFields = {};
      let constraints = [];
      for(const key in this.formData) {
        if (this.customFields.findIndex(c => c.key === key) !== -1) {
          customFields[key] = this.formData[key];
          delete this.formData[key]
        }
        if (this.userConstraints.findIndex(c => c.key === key) !== -1) {
          if (this.formData[key]) {
            constraints.push(key);
          }
          delete this.formData[key]
        }
      }

      for (const key in customFields) {
        const type = this.formList.find(e => e.key === key).type;
        if (type && type === 'NUMBER') {
          customFields[key] = customFields[key] ? Number(customFields[key]) : null;
        }
      }

      this.formData.customFields = customFields;
      this.formData.constraints = constraints;

      const body = [this.formData];

      this.handleUpdateConnections(body)
        .then(data => {
          delay(() => {
            this.$router.push({
              name: 'PreviewConnection',
              params: {
                id: data[0].id,
              }
            })
          }, 2000)
        });
    },
  },
};
</script>

<style lang="scss">
.edit-connection {
  height: 100%;
  .edit-connection-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
      .custom-multi-select.zq--multiselect.zq--multiselect__select {
        .multiselect__select {
          opacity: 1;
        }
      }
    }
  }
}
</style>
